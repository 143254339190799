import Ajv from "ajv";
import {ErrorMessageMapper} from "ajv-i18n";
import mapper_en from "ajv-i18n/localize/en";
import i18next from "i18next";

import contextTypeSchema from "../schemas/context-type.schema.json";
import simulationStateSchema from "../schemas/simulation-state.schema.json";
import uiSchemaSchema from "../schemas/ui-schema.schema.json";

let mapper: ErrorMessageMapper = mapper_en;

function setLanguage(lang: string) {
    import(`ajv-i18n/localize/${lang}`)
        .then(s => {
            if (typeof s?.default === "function") {
                mapper = s.default;
            }
        })
        .catch(() => {
            const idx = lang.indexOf("-");
            if (idx > 0) {
                setLanguage(lang.substring(0, idx));
            }
        });
}

if (i18next.language) {
    setLanguage(i18next.language);
}

i18next.on("languageChanged", lang => {
    setLanguage(lang);
});

const ajv = new Ajv({
    allErrors: true,
});

export interface SchemaValidationSuccess {
    type: "success";
}

export interface SchemaValidationFailure {
    type: "failure";
    errors: Ajv.ErrorObject[];
}

export type SchemaValidationResult = SchemaValidationSuccess | SchemaValidationFailure;

export const isValidationFailure = (result: SchemaValidationResult): result is SchemaValidationFailure => {
    return result.type === "failure";
};

export type SchemaValidationFn = (
    data: any,
    dataPath?: string,
    parentData?: object | any[],
    parentDataProperty?: string | number,
    rootData?: object | any[]
) => SchemaValidationResult;

export const compileSchema = (schema: object) => {
    const validator = ajv.compile(schema);
    return (
        data: any,
        dataPath?: string,
        parentData?: object | any[],
        parentDataProperty?: string | number,
        rootData?: object | any[]
    ): SchemaValidationResult => {
        const valid = validator(data, dataPath, parentData, parentDataProperty, rootData);
        if (!valid) {
            if (validator.errors) {
                mapper?.(validator.errors);
            }
            return {
                type: "failure",
                errors: validator.errors ?? [],
            };
        }
        return {type: "success"};
    };
};

export const validate = (schemaKeyRef: object | boolean, data: any): SchemaValidationResult => {
    const valid = ajv.validate(schemaKeyRef, data);
    if (typeof valid !== "boolean") {
        throw new Error("No $async validation allowed");
    }
    if (!valid) {
        if (ajv.errors) {
            mapper?.(ajv.errors);
        }
        return {
            type: "failure",
            errors: ajv.errors ?? [],
        };
    }
    return {type: "success"};
}

export const validateJsonSchema = (schema: object): SchemaValidationResult => {
    const valid = ajv.validateSchema(schema);
    if (valid) {
        return {type: "success"};
    }
    if (ajv.errors) {
        mapper?.(ajv.errors);
    }
    return {
        type: "failure",
        errors: ajv.errors ?? [],
    };
};

export const translateValidationErrors = (errors: Ajv.ErrorObject[]) => {
    return mapper?.(errors);
};

//Force internal schemas compilation in correct order.
export const validateContextTypeSchema = compileSchema(contextTypeSchema);
export const validateSimulationStateSchema = compileSchema(simulationStateSchema);
export const validateUiSchema = compileSchema(uiSchemaSchema);
