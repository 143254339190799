import {ErrorObject} from "ajv";
import {ErrorInfo} from "./error-info";

/*eslint-disable no-new-func*/

export interface ImportSucceeded<T> {
    type: "success";
    result: T;
}

export interface ImportFailed {
    type: "failure";
    errors: ErrorInfo[];
}

export type ImportResult<T> = ImportSucceeded<T> | ImportFailed;

export const importSuccess = <T>(result: T): ImportSucceeded<T> => {
    return {
        type: "success",
        result,
    };
};

export const importFailure = (errors: ErrorInfo[]): ImportFailed => {
    return {
        type: "failure",
        errors,
    };
};

export const isImportFailure = <T>(result: ImportResult<T>): result is ImportFailed => {
    return result.type === "failure";
};

export const mapAjvErrors = (errors: ErrorObject[], basePath?: string): ErrorInfo[] => {
    return errors.map(e => {
        const params = Object.entries(e.params)
            .map(([key, value]) => `${key}: ${value}`)
            .join("; ");
        return {
            dataPath: (basePath ?? "") + (e.dataPath ?? ""),
            message: `${e.message} (${params})`,
        };
    });
};
