import i18n, {TFunction} from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import {atom} from "recoil";

import {getBasePath} from "../utils/get-base-url";

let initPromise: Promise<TFunction> | undefined = undefined;

export const currenti18nLanguage = atom<string | undefined>({
    key: "currenti18nLanguage",
    default: undefined,
});

export const initializeI18n = () => {
    if (!initPromise) {
        let basePath = getBasePath();
        if (basePath.length > 0 && basePath[basePath.length - 1] !== "/") {
            basePath += "/";
        }
        initPromise = i18n
            .use(HttpApi)
            .use(LanguageDetector)
            .use(initReactI18next)
            .init({
                fallbackLng: "en",
                //debug: true,
                detection: {
                    caches: [],
                },
                //keySeparator: false, // we do not use keys in form messages.welcome
                backend: {
                    loadPath: basePath + "i18n/{{lng}}.json",
                },
                interpolation: {
                    escapeValue: false, // react already prevents xss
                    formatSeparator: ",",
                },
            });
    }
    return initPromise;
};
