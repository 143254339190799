import { clone } from "ramda";

interface DepthFirstCallbacks<V> {
    enterVertex?: (args: {currentVertex: V; previousVertex?: V}) => void;
    leaveVertex?: (args: {currentVertex: V; previousVertex?: V}) => void;
    allowTraversal?: (args: {currentVertex: V; previousVertex?: V; nextVertex: V}) => boolean;
    getNeighbors: (currentVertex: V) => V[];
}

function initCallbacks<V>(callbacks: DepthFirstCallbacks<V>) {
    const allowTraversalCallbackGenerator = () => {
        const seen: V[] = [];
        return ({nextVertex}: {nextVertex: V}) => {
            if (!seen.includes(nextVertex)) {
                seen.push(nextVertex);
                return true;
            }
            return false;
        };
    };

    return {
        ...callbacks,
        allowTraversal: callbacks.allowTraversal ?? allowTraversalCallbackGenerator(),
    };
}
function depthFirstSearchRecursive<V>(
    currentVertex: V,
    previousVertex: V | undefined,
    callbacks: DepthFirstCallbacks<V>
) {
    callbacks.enterVertex?.({currentVertex, previousVertex});
    for (const nextVertex of callbacks.getNeighbors(currentVertex)) {
        if (callbacks.allowTraversal!({previousVertex, currentVertex, nextVertex})) {
            depthFirstSearchRecursive(nextVertex, currentVertex, callbacks);
        }
    }
    callbacks.leaveVertex?.({currentVertex, previousVertex});
}

export function depthFirstSearch<V>(startVertex: V, callbacks: DepthFirstCallbacks<V>) {
    depthFirstSearchRecursive(startVertex, undefined, initCallbacks(callbacks));
}

interface FindCycleCallbacks<V> {
    getNeighbors: (currentVertex: V) => V[];
}

export function detectCycle<V>(startVertex: V, callbacks: FindCycleCallbacks<V>): V[] | undefined {
    const visited: V[] = [];
    let cycle: V[] | undefined;
    depthFirstSearch(startVertex, {
        getNeighbors: callbacks.getNeighbors,
        enterVertex: ({currentVertex: v}) => {
            if (visited.includes(v)) {
                cycle = clone(visited);
            }
            visited.push(v);
        },
        leaveVertex: () => {
            visited.pop();
        },
        allowTraversal: () => !cycle,
    });
    return cycle;
}